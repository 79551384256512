<!-- 管理员管理 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<style scoped>
/deep/.el-form-item {
  margin-bottom: 20px;
}

/deep/.el-form-item__error {
  left: 75px;
}

/deep/ .el-form-item__content {
  line-height: inherit;
}
</style>
<script>
import Layout from "@/views/layouts/main";
import UserHeader from "../user-header.vue";
import {
  getUserAdmin,
  deleteUserAdmin,
  getUserAdminDetails,
  addUserAdmin,
  updateUserAdminSfsd,
  updateUserAdmin,
} from "@/api/admin/user/userAdmin.js";
import {
  updatePermission,
  updatePassword,
} from "@/api/admin/user/institutionManage.js";
import {
  getDicts
} from "@/api/common/dict.js";
import YzSelect from "@/components/form/yzSelect.vue";
import YzSearch from "@/components/form/yzSearch.vue";
import UserPromission from "@/components/user/user-promission.vue";
import {
  resetLoginTimes
} from "@/api/admin/user/userAdmin";
// import Multiselect from "vue-multiselect";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    UserHeader,
    YzSelect,
    YzSearch,
    UserPromission,
    // Multiselect
  },
  data() {
    const checkIdcard = (rule, value, cb) => {
      const regIdcard =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if (regIdcard.test(value)) {
        return cb();
      }
      cb(new Error("请输入正确的身份证号"));
    };
    return {
      items1: [{
        text: "用户首页",
        href: "/admin",
      },
        {
          text: "用户管理",
          active: true,
        },
      ],
      items: [],
      checked: true,
      defaultvalue: 3,
      currentpage: 3,
      pageData: {
        nowPage: 1,
        pageSize: 20,
        total: 0,
      },
      form: {},
      userShow: false,
      dict: {},
      search: [],
      showPromission: false,
      rules: {
        xm: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur",
        }, ],
        yhzh: [{
          required: true,
          message: "请输入账号",
          trigger: "blur",
        }, ],
        sfzjh: [{
          required: true,
          message: "请输入身份证件号",
          trigger: "blur",
        },
          {
            validator: checkIdcard,
            trigger: "change",
          },

        ],
        mm: [{
          required: true,
          message: "请输入登录密码",
          trigger: "blur",
        },

        ],
        yhlb: [{
          required: true,
          message: "请选择用户类别",
          trigger: "change",
        }, ],
      },
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val;
      this.getList();
    },
    getList() {
      getUserAdmin("hide", this.pageData).then((res) => {
        if (res.status) {
          this.items = res.data;
          this.pageData.total = res.total;
          this.pageData.pageNum = res.pageNum;
          this.pageData.pageSize = res.pageSize;
        }
      });
    },
    searchClick() { //搜索时先将分页的页码归1
      this.pageData.pageNum = 1; //当前页
      this.getList() //渲染数据的函数
    },
    editUser(mid) {
      getUserAdminDetails(mid).then((res) => {
        if (res.status) {
          this.form = res.data;
          this.userShow = true;
        }
      });
    },
    addUser() {
      let error = ""
      this.$refs["form"].validateField(
          ["yhzh", "xm", "yhlb", "sfzjh", "mm"],
          (valid) => {
            if (valid) {
              error = valid;
            }
          }
      );
      if (!error) {
        if (this.form.bmdm == '') {
          this.form.bmmc = ''
        }
        addUserAdmin(this.form).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.userShow = false;
            this.getList();
          }
        });
      }

    },
    updateUser() {
      let error = ""
      this.$refs["form"].validateField(
          ["yhzh", "xm", "yhlb", "sfzjh"],
          (valid) => {
            if (valid) {
              error = valid;
            }
          }
      );
      if (!error) {
        if (this.form.bmdm == '') {
          this.form.bmmc = ''
        }
        updateUserAdmin(this.form.yhzh, this.form).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "更新成功!",
            });
            this.userShow = false;
            this.getList();
          }
        });
      }
    },
    sfsdChange(mid, sfsd) {
      updateUserAdminSfsd(mid, sfsd).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "更新成功!",
          });
        }
      });
    },
    deleteUser(mid, xm) {
      this.$confirm("系统将删除【"+xm+"】, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteUserAdmin(mid).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        });
      });
    },
    getPermission(yhzh) {
      getUserAdminDetails(yhzh).then((res) => {
        if (res.status) {
          this.form = res.data;
          this.showPromission = true;
        }
      });
    },
    updatePermission(mid, mkqx) {
      updatePermission(mid, {
        mkqx: mkqx
      }).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "更新成功!",
          });
          this.showPromission = false;
          this.getList();
        }
      });
    },
    startUpdate(yhzh, mm) {
      updatePassword(yhzh, mm).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "更新成功",
          });
        }
      });
    },
    sfzjChange() {
      if (this.form.sfzjh && this.form.sfzjh.length >= 18) {
        this.form.mm = this.form.sfzjh.substring(12);
        this.$forceUpdate();
      }
    },
    resetLoginTimes(mid) {
      resetLoginTimes(mid).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "更新成功",
          });
          this.getList()
        }
      });
    }
  },
  mounted() {
    getDicts("yhlb", "bm").then((res) => {
      this.dict = res;
      this.dict.yhlb = [this.dict.yhlb[1]];
      this.search = [{
        title: "部门",
        bound: ["bmdm"],
        type: "select",
        dict: JSON.parse(JSON.stringify(res.bm)),
      },
        {
          title: "姓名/账号/身份证件号",
          bound: "keyword",
          type: "input",
        },
      ];
    });
    this.getList();
  },
};
</script>

<template>
  <Layout>
    <UserHeader :items="items1" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <yz-search :data="search" v-model="pageData" @seachList="searchClick"></yz-search>
                <b-button @click="
                    userShow = true;
                    form = {};
                  " v-b-modal.add-user variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加管理员用户</b-button>
                <a href="/admin/baseOrganization">
                  <b-button variant="outline-info" class="flexList mr-2 condition"><i
                      class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>部门设置</b-button>
                </a>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover">
                <thead class="thead-light">
                <tr>
                  <th style="width: 5%">序号</th>
                  <th style="width: 10%">姓名</th>
                  <th style="width: 10%">账号</th>
<!--                  <th>部门</th>-->
                  <th style="width: 10%">职务</th>
                  <th style="width: 10%">类别</th>
                  <th style="width: 10%">联系电话</th>
                  <th style="width: 10%">最后登录时间</th>
<!--                  <th style="width: 5%;">修改人</th>-->
                  <th style="width: 10%;">修改时间</th>
                  <th style="width: 8%">是否锁定</th>
                  <th style="width: 8%">重置登录</th>
                  <th style="width: 8%">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in items" :key="i">
                  <td scope="row">{{ (pageData.pageNum - 1) * pageData.pageSize +  i + 1 }}</td>
                  <td>
                    <a href="javascript:;" class="blue-font"
                       @click="$yzAdminUserInfo().show(item.yhzh)">{{ item.xm }}</a>
                  </td>
                  <td>{{ item.yhzh }}</td>
<!--                  <td>{{ item.bmmc }}</td>-->
                  <td>{{ item.zw }}</td>
                  <td>管理员</td>
                  <td>{{ item.yddh }}</td>
                  <td>{{ timeSlice(item.zhdlsj) }}</td>
<!--                  <td>{{item.modifyUser}}</td>-->
                  <td>{{timeSlice(item.modifyTime)}}</td>
                  <td>
                    <b-form-checkbox v-model="item.sfsd" switch @change="sfsdChange(item.mid, item.sfsd)"
                                     class="switch-check">
                    </b-form-checkbox>
                  </td>
                  <td>
                    <a href="javascript:;" class="blue-font" @click="resetLoginTimes(item.yhzh)">点击重置</a>
                  </td>
                  <td class="tab-icon">
                    <a href="javascript:;" @click="editUser(item.mid)"><i
                        class="iconfont icon-edit-two text-dark align-middle mr-1"></i></a><i
                      @click="getPermission(item.yhzh)" class="iconfont icon-key align-middle mr-1"></i><i
                      @click="deleteUser(item.mid, item.xm)"
                      class="iconfont icon-riLine-delete-bin-line align-middle mr-1"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                             layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->
    <b-modal v-model="userShow" id="add-user" centered size="m" :title="(!form.mid?'添加':'修改') + '新用户'"
             title-class="font-18" hide-footer>
      <div>
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="yhzh">
            <div class="d-flex check-distri ">
              <label>登录账号：</label>
              <div class="col-sm-10 p-0 flexList">
                <!-- <el-input type="text" v-model="form.yhzh" autocomplete="off" size="mini" placeholder="登录账号"> </el-input> -->
                <input v-model="form.yhzh" type="text" placeholder="登录账号" class="form-control w-100 h30" />
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="xm">
            <div class="d-flex check-distri">
              <label>用户姓名：</label>
              <div class="col-sm-10 p-0">
                <input v-model="form.xm" type="text" placeholder="用户姓名" class="form-control w-100 h30" />
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="yhlb">
            <div class="d-flex check-distri ">
              <label>用户类别：</label>
              <div class="col-sm-10 p-0">
                <yz-select :dict="dict.yhlb" :bound="['yhlb']" v-model="form">
                </yz-select>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="bm">
            <div class="d-flex check-distri ">
              <label>所属部门：</label>
              <div class="col-sm-10 p-0">
                <yz-select :dict="dict.bm" :bound="['bmdm', 'bmmc']" v-model="form">
                </yz-select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri ">
              <label style="width: 15%">证件号：</label>
              <div class="col-sm-10 p-0 flexList">
                <input @input="sfzjChange" v-model="form.sfzjh" type="text" placeholder="身份证件号（后6位为登陆默认密码）"
                       class="form-control w-100 h30" />
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="!form.mid">
            <div class="d-flex check-distri ">
              <label>登录密码：</label>
              <div class="col-sm-10 p-0 flexList">
                <input v-model="form.mm" type="text" placeholder="登录密码" class="form-control w-100 h30" />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri ">
              <label>联系电话：</label>
              <div class="col-sm-10 p-0">
                <input v-model="form.yddh" type="text" placeholder="联系电话" class="form-control w-100 h30" />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri ">
              <label>电子邮件：</label>
              <div class="col-sm-10 p-0">
                <input v-model="form.dzxx" type="text" placeholder="电子邮件" class="form-control w-100 h30" />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri ">
              <label>用户职务：</label>
              <div class="col-sm-10 p-0">
                <input v-model="form.zw" type="text" placeholder="用户职务" class="form-control w-100 h30" />
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="text-center mt-3 check-modal">
        <button type="button" class="btn btn-info h30 mr-2" @click="form.mid ? updateUser() : addUser()">
          提交操作
        </button>
      </div>
    </b-modal>
    <!-- 弹窗结束 -->

    <!-- 弹窗开始 -->
    <!--添加机构用户-->
    <b-modal v-model="showPromission" centered :title="'当前修改【'+form.xm+'】的权限'" size="lg" title-class="font-18" hide-footer>
      <div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0">用户权限：</label>
          <user-promission v-model="form.mkqx"></user-promission>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 9%">密码：</label>
          <div class="col-sm-10 p-0 flexList">
            <input type="text" v-model="form.mm" placeholder="新密码" class="form-control w-50 h30" />
            <button style="margin-left: 10px" type="button" class="btn btn-info h30 w-md mr-3"
                    @click="startUpdate(form.yhzh, form.mm)">
              确定修改密码
            </button>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">
        <button type="button" class="btn btn-info h30 w-md mr-3" @click="updatePermission(form.mid, form.mkqx)">
          确定修改
        </button>
        <button type="button" class="btn btn-secondary h30 w-md" @click="showPromission = false">
          取消
        </button>
      </div>
    </b-modal>

    <!-- 弹窗结束-->
  </Layout>
</template>
